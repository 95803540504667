.stef__footer{
    
    display: flex;
    color:white;
    justify-content: space-around;
    align-items: center;
    margin: 0 6rem 0 6rem;
    padding-top: 2rem;
    padding-bottom: 2rem;;
}
.stef__footer-links{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    width: 80%;
    position: relative;
}

hr.crta {
    border: none;
    height: 1px;
    background-color: #2e2e2e;
    margin: 0 6rem;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.8);
}

.footer-padding-top{
    margin-top: auto;
    padding-top: 2rem;
}


p{
    font-family: var(--font-family);
    font-size: 25px;
}

.link-mail-telefon{
    font-family: var(--font-Bebas);
    font-size: 28px;
    font-weight: 200;
    color: #2E2E2E;
    justify-content: center;
    text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
}

.link-mail-telefon:hover{
    color: var(--color-text-2);
}

.social{
    text-transform: uppercase;
    font-weight: 700;
    padding: 0 20px;
}
.social:hover{
    color: var(--color-text);
}

.ikona{
    width: 30px;
    transition: transform 0.3s ease;
    
}

.ikona{
    box-shadow: 4px 3px 6px rgba(0,0,0,0.8);
}

.ikona:hover{
    box-shadow: none;
    transform: scale(1.2);
    filter: invert(1);
}

.footer-ikone {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%; /* These lines move the element to be centered in the .stef__footer-links */
    transform: translateX(-50%);
  }

@media screen and (max-width: 850px){
    .stef__footer{
        margin: 0rem 1rem;
    }
    .stef__footer-links{
        align-items: center;
        flex-direction: column;
        margin: 1rem 0;
        width: 95%;
    }
    .stef_footer-links_item{
        padding: 0.5rem 0;
    }
    .social{
        padding: 0 10px;
    }
}

@media screen and (max-width: 1080px) {
    .footer-ikone {
      position: static;
      justify-content: start; /* Or revert to any alignment you had before */
      transform: none;
    }
  }
