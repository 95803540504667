.video-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 5rem;
}

.video-wrapper {
  flex: 0 0 100%; /* This will make one video appear in a row on smaller screens */
  margin: 1%; /* Little bit of margin for some spacing */
}

@media screen and (min-width: 768px) {
  .video-wrapper {
    flex: 0 0 48%; /* This will make two videos appear in a row on larger screens */
  }
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* Maintain 16:9 aspect ratio */
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1; /* Ensure thumbnail and play button are above the video */
}

.thumbnail.visible {
  display: block;
}

.thumbnail.hidden {
  display: none;
}


.vimeo-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Rest of your CSS... */

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensure play button is above the thumbnail and the video */
  cursor: pointer;
}
.play-button svg {
  fill: var(--color-text-2);  /* Change this to any color you want for the play button */
}

@media (max-width: 1080px) {
  svg {
      width: 70px;
  }
}
/* sizes for medium screens */
@media (max-width: 768px) {
  svg {
      width: 60px;
  }
}






.play-button:hover svg {
  fill: var(--color-text);  /* Change the color of the play button icon when hovering over the button */
}

@media screen and (max-width: 600px) {
  .video-grid {
    padding: 0 1rem;
  }
  .video-wrapper {
    margin: 5%; /* Little bit of margin for some spacing */
  }
}