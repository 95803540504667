.stef__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0rem 6rem;
}

.stef__navbar-links {
    flex:1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.stef__navbar-links_logo{
    margin-right: 2rem;
}
.stef__navbar-links_logo img{
    height: 94px;
}

.stef__navbar-links_container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    
}

.stef__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.stef__navbar-links_container p,
.stef__navbar-sign p,
.stef__navbar-menu_container p{
    color: var(--color-text-2);
    font-family: var(--font-Bebas);
    font-weight: 500;
    font-size: 23px;
    line-height: 25px;
    text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
    margin: 0 1rem;
    cursor: pointer;
}

.stef__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.stef__navbar-menu svg{
    cursor: pointer;
}

.stef__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-bg);
    padding: 0 1rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.stef__navbar-menu_container p{
    margin: 1rem 0;
}

@media screen and (max-width: 1050px){
    .stef__navbar-links_container{
        display: none;
    }
    .stef__navbar-menu{
        display: flex;
    }
}

@media screen and (max-width: 700px){
    .stef__navbar{
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px){
    .stef__navbar{
        padding: 0 1rem 3rem 0;
    }
    .stef__navbar-menu_container{
        top: 20px;
    }
}

.active-link {
    color: var(--color-text-2);
    text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
  }
  
  .not-active{
    color: var(--color-text);
    font-family: var(--font-Bebas);
    text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
}

.not-active:hover{
    color:var(--color-text-2);
}