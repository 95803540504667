.contactForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0; /* adjust as per your header/footer height */
  }
  
  .inputRow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 800px; /* adjust as per your preference */
    margin-bottom: 20px;
  }
  
  .inputRow input,
  .inputRow textarea {
    flex: 1;
    margin: 0 10px;
    padding: 20px;
    background: black;
    color: white;
    border: none;
    border-bottom: 1px solid white;
  }
  
  .inputRow textarea {
    height: 100px; /* adjust as per your preference */
  }
  
  .submitBtn {
    padding: 10px 20px;
    cursor: pointer;
  }

  .sirina-contact{
    padding: 0 6rem;
  }
  
  .kontakt-naslov{
        font-size: 78px;
        color: var(--color-text);
        font-weight: 200;
        font-family: var(--font-Bebas);
        padding-top: 5rem;
        text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
  }

  .errorMsg {
    color: white;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .inputField {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 10px;

  }
  

  .inputButton{
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .inputButton input{
    background: #2E2E2E;
    text-align: center;
    border:none;
    color: var(--color-text-2);
    font-family: var(--font-Bebas);
    font-size: 30px;
    text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
  }

  .inputButton input:hover{
    color: var(--color-text);
  }

  .inputButton input:disabled {
    background: #888; /* Gray or any color you prefer */
    cursor: not-allowed;
  }
  
  .inputButton input:disabled:hover {
    color: var(--color-text-2); /* or whatever color you want when disabled and hovered */
  }
  
  .kontakt-txt{
    color: var(--color-text-2);
    font-family: var(--font-Bebas);
    font-size: 23px;
    padding-left: 10px;
  }

  .kontakt-telefon{
    margin: 50px 0 10px 0;
  }
  


  @media screen and (max-width: 880px) {
    .inputRow{
      display: block;
    }
    .contactForm {
      align-items: initial;
    }
    .kontakt-mail,
    .kontakt-telefon{
      text-align: center;
    }
  }

  @media screen and (max-width: 550px) {
    .sirina-contact{
      padding: 0 1rem;
    }
    .kontakt-naslov{
      text-align: center;
      font-size: 60px;
    }
  }