.sirina-teksta{
    padding: 3rem 6rem;
    margin: auto;
    
    
}

.director{
    font-family: var(--font-Bebas);
    font-weight: 100;
    color:var(--color-text-2);
    font-size: 23px;
    margin: 0;
    padding: 0;
    text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
}

.jan-stefancic{
    font-family: var(--font-Bebas);
    font-weight: 100;
    color:var(--color-text);
    font-size: 105px;
    padding: 0;
    margin: 0; /* Adjust this line */
    line-height: 0.9;
    text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
}



p{
    font-weight: 100;
    font-size: 18px;
}

@media screen and (max-width: 600px){
    .sirina-teksta{
        padding: 0 20px;
    }
    .jan-stefancic{
        font-size: 40px;
    }
    .director{
        font-size: 15px;
        padding-bottom: 20px;
    }
}