.bio-container{
    display: flex;
    padding: 0 15rem 10rem 15rem;
}

.bio-naslov-sirina{
    padding: 0 15rem;
}

.levi-bio-container{
    width:25%;
    margin-right: 20px;
    /* padding-left: 20%; */
}

.desni-bio-container{
    width:75%;
}

.bio-naslov{
    padding: 5rem 0 1rem 0;
    font-size: 78px;
    color: var(--color-text);
    font-weight: 200;
    font-family: var(--font-Bebas);
    text-shadow: 4px 3px 6px rgba(0,0,0,0.8);
}

.bio-opis{
    color:var(--color-text-2);
    font-family: var(--font-Bahnschrift);
    font-weight: 100;
    font-size: 20px;
    padding-right: 40px;
    line-height: 1.6rem;
}

.zgornja-slika{
    width: 100%;
}

.spodnji-sliki{
    display: flex;
    width: 100%;
    padding-top: 20px;
}

 .spodnja-slika-leva{
    width: 60%;
    height: auto;
    padding-right: 20px;
    max-width: 100%;
    object-fit: cover;
}
.spodnja-slika-desna{
    width: 40%;
    max-width: 100%;
    height: auto;
    object-fit: cover;
} 




/* @media screen and (max-width: 1800px) {
    .levi-bio-container{
        padding-left: 15%;
    }
  }

  @media screen and (max-width: 1600px) {
    .levi-bio-container{
        padding-left: 10%;
    }
  } */

  @media screen and (max-width: 1550px) {
    .bio-container{
        display: flex;
        padding: 0 10rem 10rem 10rem;
    }
    .levi-bio-container{
        width:30%;
        margin-right: 20px;
        /* padding-left: 20%; */
    }
    
    .desni-bio-container{
        width:70%;
    }
    .bio-naslov-sirina{
        padding: 0 10rem;
    }
  }

  @media screen and (max-width: 1450px) {
    .levi-bio-container{
        padding-left: 0;
    }
    .bio-container{
        display: flex;
        
    }
    .bio-opis{
        line-height: 1.5rem;
        padding-right: 10px;
        font-size: 19px;
    }
  }
  @media screen and (max-width: 1350px) {
    
    .bio-opis{
        font-size: 16px;
    }
  }

  @media screen and (max-width: 1250px) {
    
    .bio-opis{
        font-size: 14px;
        line-height: 1.3rem;
    }
  }

  @media screen and (max-width: 1080px) {
    .bio-container{
        display: flex;
        padding: 0 2rem 5rem 2rem;
    }
    .bio-naslov-sirina{
        padding: 0 2rem;
    }

    .bio-naslov{
        font-size: 60px;
    }
    .bio-opis{
        font-size: 16px;
    }
  }

  @media screen and (max-width: 870px) {
    .bio-naslov{
        font-size: 40px;
    }
    .bio-opis{
        font-size: 14px;
        line-height: 1.3rem;
        padding-right: 0px;    
    }
  }

  @media screen and (max-width: 800px) {
    .bio-container{
        display: block;
        padding: 0 5%;
    }
    .levi-bio-container{
        width:100%;
        padding: 20px 0;
    }
    
    .desni-bio-container{
        width:100%;
        padding-bottom: 20px;
    }
    .bio-naslov-sirina{
        padding: 0 5%;
    }
  }
